<template>
    <v-card tile
            elevation="2"
            class="mt-1"
    >
        <v-card-title class="tiny py-1">
            {{ name }}
            <v-spacer />
            <span class="grey--text font-weight-regular">{{ createdAt }}</span>
            <delete-comment v-if="canDelete"
                            :comment="comment"
                            class="ml-2"
            />
            <resolve class="ml-2"
                     :status="comment.status"
                     :comment-id="comment.id"
            />
        </v-card-title>
        <v-card-text class="mt-4 comment-message">
            {{ comment.message }}
        </v-card-text>
    </v-card>
</template>

<script>
    import Resolve from '@/components/controls/comments/sidebar/resolve.vue';
    import DeleteComment from '@/components/controls/comments/sidebar/delete-comment.vue';
    import {mapGetters, mapState} from 'vuex';
    import Gate from '@/services/gate';

    export default {
        name: 'Comment',
        components: {DeleteComment, Resolve},
        props: {
            comment: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters('auth', ['user', 'isSuperAdmin', 'isSystemAdmin', 'isLoggedIn', 'canEditUserCompany']),
            ...mapState('comments', ['video']),
            ...mapState('auth', ['user']),
            createdAt() {
                return this.comment.created_at_formatted;
            },
            name() {
                return this.comment.name ?? this.comment.user_name;
            },
            canDelete() {
                if (!this.isLoggedIn) {
                    return false;
                }

                if (this.comment.a_id === this.user.id) {
                    return true;
                }

                if (this.isSystemAdmin || this.isSuperAdmin) {
                    return true;
                }

                return !!(this.canEditUserCompany && Gate.allows(this.user, 'manage-videos-of-libraries', this.video.library));
            }
        }
    };
</script>

<style scoped>
.tiny {
    font-size: 12px;
    line-height: 1.5em;
    border-bottom: 1px solid #ccc;
}

.comment-message {
    white-space: pre-line;
}
</style>