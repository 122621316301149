var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"property-sheet file-listing-table",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"encodings-text"},[_vm._v(" Encodings ")]),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":true,"max-width":"280px","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 my-1 button-minwidth generate",attrs:{"small":"","color":"primary","disabled":_vm.resolutionList.length === 0}},'v-btn',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v("Generate encodings")])])]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-card',[_c('v-toolbar',{staticClass:"pr-2",attrs:{"light":"","dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Generate")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.closeMenu}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('label',{attrs:{"for":"select-resolution"}},[_vm._v("Resolutions")]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"multiple":"","active-class":""},model:{value:(_vm.selectedResolutions),callback:function ($$v) {_vm.selectedResolutions=$$v},expression:"selectedResolutions"}},_vm._l((_vm.resolutionList),function(resolution){return _c('v-list-item',{key:resolution.name,attrs:{"value":resolution.height,"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"dense":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(resolution.name)+" ")])],1)]}}],null,true)})}),1)],1),_c('label',{staticClass:"encoding-info"},[_vm._v("Encoding is only available in mp4 format.")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeMenu}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":!_vm.selectedResolutions,"color":"primary","text":""},on:{"click":_vm.requestEncoding}},[_vm._v(" Generate & send mail ")])],1)],1)],1)],1)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('v-list',{staticClass:"tiny-actions py-0",attrs:{"dense":"","flat":""}},[_c('v-list-item-group',_vm._l((_vm.availableEncodings),function(encoding){return _c('v-list-item',{key:encoding.label,staticClass:"pa-0 file-name"},[_c('v-list-item-content',{staticClass:"pa-0 mr-1"},[_c('div',{staticClass:"d-flex d-row"},[_c('div',{staticClass:"format-variant"},[_vm._v(" "+_vm._s(encoding.label)+" / "+_vm._s(encoding.extension)+" ")]),_c('div',{staticClass:"format-actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":!encoding.ready,"href":_vm.downloadEncodingUrl(encoding)}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":!encoding.ready},on:{"click":function($event){return _vm.copyEncodingUrl(encoding)}}},on),[_c('v-icon',{staticClass:"primary--text file-copy-icon"},[_vm._v(" file_copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy download URL")])])],1),(!encoding.ready)?_c('div',{staticClass:"progress-text"},[_vm._v(" Generating ")]):_vm._e(),(encoding.ready)?_c('div',{staticClass:"progress-text"}):_vm._e()])])],1)}),1)],1)],1)]),_c('tr',[_c('td',{attrs:{"colspan":"1"}}),_c('td',[_c('v-btn',{staticClass:"mr-2 my-1 button-minwidth",attrs:{"small":"","color":"primary","disabled":_vm.sendMailDisabled},on:{"click":_vm.sendMail}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-email-send ")]),_c('span',{staticClass:"mr-1"},[_vm._v("Send mail")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }