<template>
    <div>
        <v-container
            fluid
            class="pb-0"
        >
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    xl="9"
                    lg="8"
                    md="7"
                >
                    <comments-element-container />
                </v-col>
                <v-col
                    v-if="player"
                    cols="12"
                    sm="12"
                    xl="3"
                    lg="4"
                    md="5"
                    class="editor-sidebar"
                    :class="additionalClasses"
                >
                    <v-row class="sidebar-top">
                        <v-col
                            cols="12"
                            class="pt-2"
                        >
                            <h1 class="page-header mb-0">
                                <span v-if="video">{{ video.title }}</span>
                            </h1>
                            <span :key="currentTimeIndex"
                                  class="grey--text"
                            >
                                <time-index-input :value="currentPlayerPosition"
                                                  should-confirm
                                                  @input="updatePlayerPosition"
                                />
                            </span>
                        </v-col>
                    </v-row>
                    <div>
                        <comment-form />
                    </div>
                    <v-row class="sidebar-content">
                        <v-col
                            cols="12"
                            class="px-lg-0 pt-0"
                        >
                            <comments-sidebar :key="updateKey" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import CommentsSidebar from '@/components/controls/comments/comments-sidebar.vue';
    import CommentsElementContainer from '@/components/controls/comments/comments-element-container.vue';
    import {simpleVerify} from '@/services/url-hashing';
    import CommentForm from '@/components/controls/comments/sidebar/comment-form.vue';
    import TimeHelper from '@/time-helper';
    import {$auth} from '@/services/auth';
    import TimeIndexInput from '@/components/controls/video-metadata/editor/TimeIndexInput.vue';

    export default {
        name: 'Comments',
        components: {
            TimeIndexInput,
            CommentForm,
            CommentsSidebar, CommentsElementContainer},
        data() {
            return {
                updateKey: 0,
                uploadKey: 0,
            };
        },
        computed: {
            ...mapState('comments', [
                'player',
                'currentPlayerPosition',
                'video',
            ]),
            ...mapGetters('comments', ['videoId', 'activeElement']),
            ...mapGetters('auth', ['isLoggedIn']),
            additionalClasses() {
                const breakPoint = this.$vuetify.breakpoint.lgAndUp;
                return {
                    'app-sidebar': breakPoint,
                    'mt-n3': breakPoint,
                    'pb-0': breakPoint,
                };
            },
            currentTimeIndex() {
                return TimeHelper.fromMsToHisU(this.currentPlayerPosition * 1000);
            }
        },
        methods: {
            ...mapActions('comments', ['getVideo', 'getComments']),
            ...mapMutations('comments', ['setVideo']),
            getVideoIdFromRoute() {
                const videoId = this.$route.params.videoId;
                const hash = this.$route.params.hashId;
                if (!videoId || !hash || !simpleVerify(videoId, hash)) {
                    this.$router.push({
                        'path': '/not-found'
                    });
                }
                return videoId;
            },
            longPoll() {
                this.getComments({videoId: this.videoId});
            },
            updatePlayerPosition(value) {
                this.player.$emit('seek', value);
                console.log(value);
            }
        },
        async created() {
            if (window.localStorage.getItem('platform_logged_in')) {
                await $auth.refresh();
            }

            const videoId = this.getVideoIdFromRoute();
            if (!this.isLoggedIn) {
                this.setVideo({
                    id: videoId
                });
            }
        },
        async mounted() {
            const videoId = this.getVideoIdFromRoute();
            if (this.isLoggedIn) {
                await this.getVideo(videoId);
            }
            await this.getComments({videoId});
            this.$options.pollInterval = setInterval(this.longPoll, 30000);
        },
        beforeDestroy() {
            clearInterval(this.$options.pollInterval);
        }
    };
</script>

<style scoped lang="scss">
.app-sidebar {
  background-color: #fff;
  border-left: 1px solid #d9d9d9;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;

  .sidebar-top {
    flex: 0 0 auto;
  }

  .sidebar-content {
    flex: 1 1 0;
    overflow-y: auto;
  }

}

.interactive-editor-toolbar {
  display: flex;
}

.toolbar-button {
  vertical-align: top;
}

</style>