<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn color="error"
                       icon
                       x-small
                       v-on="on"
                       @click="deleteCommentAction"
                >
                    <v-icon small>
                        delete
                    </v-icon>
                </v-btn>
            </template>
            <span>Delete</span>
        </v-tooltip>
        <confirm-modal v-model="isOpen"
                       title="Delete comment"
                       confirm-button="Yes, delete it"
                       cancel-button="No"
                       @confirm="confirmDelete"
                       @cancel="cancelDelete"
        >
            <p>The following comment ill be deleted. Would you like to continue?</p>
            <hr>
            <div class="d-flex">
                {{ name }}
                <v-spacer />
                <span class="grey--text font-weight-regular">{{ createdAt }}</span>
            </div>
            <hr class="mb-2">
            <div> {{ comment.message }} </div>
        </confirm-modal>
    </div>
</template>

<script>
    import ConfirmModal from '@/components/controls/modals/confirm-modal.vue';
    import {mapActions, mapGetters} from 'vuex';
    import {toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'DeleteComment',
        components: {ConfirmModal},
        data() {
            return {
                isOpen: false
            };
        },
        props: {
            comment: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapGetters('comments', ['videoId']),
            createdAt() {
                return this.comment.created_at_formatted;
            },
            name() {
                return this.comment.name ?? this.comment.user_name;
            }
        },
        methods: {
            ...mapActions('comments', ['deleteComment']),
            deleteCommentAction() {
                this.isOpen = true;
            },
            cancelDelete() {
                this.isOpen = false;
            },
            async confirmDelete() {
                console.log(this.comment.id);
                await this.deleteComment({
                    videoId: this.videoId,
                    commentId: this.comment.id
                });
                toastSuccess('Message deleted.');
            }
        }
    };
</script>

<style scoped>

</style>