import {$http, videoApiUrl} from '@/services/http';

const commentActions = {

    async getVideo({commit}, videoId) {
        return $http.get(videoApiUrl(`api/videos/${videoId}`)).then(response => {
            commit('setVideo', response.data);
        });
    },
    async getComments({commit}, {videoId}) {
        return $http.get(videoApiUrl(`api/videos/${videoId}/comments?direction=desc`)).then(commentsResponse => {
             commit('setComments', commentsResponse.data);
        });
    },
    async resolve({commit, state}, {videoId, commentId}) {
        await $http.put(videoApiUrl(`api/videos/${videoId}/comments/${commentId}/resolve`), {
             resolved: 1
        });
        const mappedComments = state.comments.map(c => {
            if (c.id === commentId) {
                return {...c, status: 1};
            }
            return {...c};
        });
        commit('setComments', mappedComments);
    },
    async deleteComment({commit, state}, {videoId, commentId}) {
        await $http.delete(videoApiUrl(`api/videos/${videoId}/comments/${commentId}`));
        const comments = state.comments.filter(c => c.id !== commentId);
        commit('setComments', comments);
    },
    async sendComment({commit, state}, {videoId, comment}){
        const {data} = await $http.post(videoApiUrl(`api/videos/${videoId}/comments`), comment);
        comment.id = data;
        const d = new Date();
        comment.created_at_formatted = d
            .toLocaleString('en-ca', {timeZone: 'Europe/Amsterdam', hour12: false})
            .replace(',', '');
        commit('setComments', [comment, ...state.comments]);
    }
};

export default commentActions;